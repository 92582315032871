import JwtService from "@/@core/auth/jwt/jwtService";
import axiosIns from "@/libs/axios";

function checkToken() {
  let data = JSON.parse(localStorage.getItem("userData"));
  if (!data) return;

  checkingTheDateAndTime();

  setInterval(() => {
    checkingTheDateAndTime();
  }, 300000);

  setInterval(() => {
    refreshToken(axiosIns);
  }, (data.access_token_expires * 1000) / 2);
}
function refreshToken(axiosIns) {
  let data = JSON.parse(localStorage.getItem("userData"));
  let formData = new FormData();
  formData.append("refresh_token", data.refresh_token);

  axiosIns
    .post(`auth/refresh-token/${data.id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      let userData = JSON.parse(localStorage.getItem("userData"));

      userData.access_token = res.data.access_token;
      userData.access_token_expires = res.data.access_token_expires;
      userData.refresh_token = res.data.refresh_token;
      userData.access_token_expDate = res.data.access_token_expDate;
      userData.created_at = res.data.created_at;
      localStorage.setItem("userData", JSON.stringify(userData));

      axiosIns.defaults.headers.common["Authorization"] = `Bearer ${res.data.access_token}`;

      const jwtService = new JwtService(axiosIns);

      jwtService.setToken(res.data.access_token);
      jwtService.setRefreshToken(res.data.access_token);
    })
    .catch((error) => {
      console.log(error);
    });
}

function checkingTheDateAndTime() {
  let data = JSON.parse(localStorage.getItem("userData"));
  let expDate = new Date(data.access_token_expDate);
  let now = new Date("2025-03-07 10:28:07");
  let tajikistanTime = now.toLocaleString("ru-RU", { timeZone: "Asia/Dushanbe" });

  if (tajikistanTime >= expDate) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    location.reload();
  } else {
    console.log("Токен ещё действителен.");
  }
}

export default { checkToken };
